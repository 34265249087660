html {
    position: relative;
    min-height: 100%;
}
.App {
  text-align: center;
}

.logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  margin-bottom: 0px;
}

.header {
  background-color: #fff;
  /*min-height: 40px;*/
  padding:30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  color: white;
  border-bottom: 1px solid #eee;
}

.App-link {
  color: #61dafb;
}

a.nav-link.active {
  background: #007bff;
  color: #fff;
}
p {margin-bottom: 0.5rem;}
h1 {
  font-size: 1.5rem;
  margin-top: .7rem;
}
h1:before {
  content: "- ";
}
h1:after {
  content: " -";
}
h2 {
  font-size: 1.2rem;
  margin-top: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0ddd5;
  border-top: 1px solid #e0ddd5;
}
h2 {
  font-size: 1.1rem;
}
.pagination-lg .page-link {
  font-size: 1.0rem;
}

.home {
  margin-top:100px;
}
.catalog {

}
.view-container {
  padding-bottom: 20px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.stepper {
  margin-top: 20px;
  padding: 30px 0 10px;
  border-top: 1px solid #eee;
}
.stepper nav {
  display:inline-block;
}
.stepper nav ul {
  margin-bottom: 0px;
}
.stepper li.disabled span {
  color: #eee;
}

.basket {
  margin-top: 20px;
  padding: 0px 0 10px 0;
  border-top: 1px solid #eee;
}
.basket h5 {
  margin:0px;
  padding:10px 0;
  color: #fff;
  background-color: #6c757d;
}
.basket .remove {
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
}
.basket .total {
  padding: 20px 0;
}

.categoriesMenu {

}
.categoriesMenu .nav {
  display:block;
  width:auto;
  display:inline-block;
}
.categoriesMenu .nav li {
  display:inline-block;
}

.selection-container {
  margin-top:30px;
  padding-bottom: 50px;
}
.selection-container .btn {
    margin:0 5px;
}
.selection-container .entry {
  padding:10px 0;
}
.selection-container .basket {
  padding: 0px;
}

/*
https://www.fourkitchens.com/blog/article/responsive-multi-column-lists-flexbox/
*/
.prestationsList {
  padding:0 10px;
  display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-top: 10px;
}
.prestationsList li {
  flex: 1 0 200px;
  box-sizing: border-box;
  background: #e0ddd5;
  color: #171e42;
  padding: 5px;
	margin-left: 10px;
	margin-top: 20px;
  list-style:none;
  border-radius: 5px;
}
@media (min-width: 410px) {
  .prestationsList li {
    max-width: calc(50% - 10px);
  }
}
@media (min-width: 620px) {
  .prestationsList li {
    max-width: calc(33.33333% - 10px);
  }
}
@media (min-width: 830px) {
  .prestationsList li {
    max-width: calc(25% - 10px);
  }
}
@media (min-width: 1040px) {
  .prestationsList li {
    max-width: calc(20% - 10px);
  }
}
@media (min-width: 1250px) {
  .prestationsList li {
    max-width: calc(16.66667% - 10px);
  }
}
@media (min-width: 1460px) {
  .prestationsList li {
    max-width: calc(14.28571% - 10px);
  }
}
@media (min-width: 1670px) {
  .prestationsList li {
    min-width: calc(12.5% - 10px);
  }
}

/*
  PlacesAutocomplete
  https://hibiken.github.io/react-places-autocomplete/
*/
.PlacesAutocomplete__map-maker-icon {
  color: #FD6C6C;
}

.PlacesAutocomplete__github-icon {
  font-size: 24px;
}

.PlacesAutocomplete__github-link {
  color: #262626;
  font-size: 20px;
}

.PlacesAutocomplete__github-link:hover,
.PlacesAutocomplete__github-link:active {
  color: #4078c0;
  text-decoration: none;
}

.PlacesAutocomplete__suggestion-icon {
  margin-right: 8px;
}

.PlacesAutocomplete__search-bar-container {
  width: 90%;
  max-width: 500px;
  margin: 40px auto 0;
}

.PlacesAutocomplete__search-input-container {
  position: relative;
}

.PlacesAutocomplete__search-input,
.PlacesAutocomplete__search-input:focus,
.PlacesAutocomplete__search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.PlacesAutocomplete__clear-button,
.PlacesAutocomplete__clear-button:active,
.PlacesAutocomplete__clear-button:focus {
  position: absolute;
  right: 16px;
  top: 25px;
  /*top: 50%;*/
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.PlacesAutocomplete__autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
  text-align: left;
}

.PlacesAutocomplete__suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.PlacesAutocomplete__suggestion-item--active {
  background-color: #fafafa;
  padding: 8px;
}

.PlacesAutocomplete__dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.PlacesAutocomplete__dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.PlacesAutocomplete__spinner {
  color: #18bc9c;
  font-size: 30px;
}

.PlacesAutocomplete__error-message {
  color: red;
}

.PlacesAutocomplete__geocode-result-header {
  font-size: 20px;
  color: #222222;
}

/*
  PlacesAutocomplete
  https://hibiken.github.io/react-places-autocomplete/
*/
.dateTime-container {
  width: 60%;
  max-width: 400px;
  margin: 40px auto 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
